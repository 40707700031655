.product-presentation-container {
  font-family: var(--font-primary);
}

.product-presentation-container .top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 25px 10px;
}

.top-bttn {
  position: relative;
  display: inline-block;
  padding: 9px 14px;
  color: white;
  font-size: var(--text-size-phone-medium);
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
}

.back-bttn {
  color: var(--color-dark-blue);
  background-color: white;
  padding-right: 0;
}

.order-bttn {
  background: var(--gradient-primary);
}

.back-bttn i {
  padding-right: 5px;
}

.order-bttn i {
  padding-left: 5px;
}

.product-presentation-container .content-container {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.product-presentation-container .content-container .text-container {
  width: 85%;
  margin-top: 40px;

  line-height: 28px;
  font-size: var(--text-size-phone-medium);
  text-align: justify;
  color: var(--color-grey);
  font-weight: lighter;
}

.product-presentation-container .monthly-payment-container {
  background-color: var(--color-soft-blue);
  padding: 15px;
  line-height: 27px;
  position: relative;
  margin-bottom: 15px;
  text-align: left;
}

.product-presentation-container .monthly-payment-container .select-container {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.product-presentation-container .monthly-payment-container select {
  padding: 8px 16px;
  font: inherit;
  font-weight: bold;
  font-size: 0.9em;
  border: none;
  outline: none;
  background-color: var(--color-dark-blue);
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.product-presentation-container .product-name {
  color: var(--color-dark-blue);
  font-size: var(--text-size-phone-large);
}

.product-presentation-container .product-price {
  font-size: var(--text-size-phone-large);
  font-weight: bold;
  color: var(--color-dark-blue);
}

.product-presentation-container .extra-presentation {
  line-height: 28px;
  margin: 30px auto;
  color: var(--color-grey);
  width: 85%;
  font-size: var(--text-size-phone-medium);
}

.product-presentation-container .casa-total .extra-presentation {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-presentation-container .casa-total .extra-presentation div {
  width: 85%;
  padding: 20px 0;
}

.product-presentation-container .individuals {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product-presentation-container .individual-product-container {
  width: 80%;
  margin: 10px 0;
  border-bottom: 1px solid var(--color-light-grey);
}

.product-presentation-container .individual-product-container .top {
  font-weight: bold;
  color: var(--color-dark-blue);
}

.product-presentation-container .individual-product-container img {
  width: 100%;
}

.product-presentation-container .pre-table {
  color: var(--color-light-blue);
  font-weight: bold;
}

.product-presentation-container .properties-table {
  margin: 30px 0;
}

.product-presentation-container .table-row {
  background-color: var(--color-soft-blue);
  display: flex;
  border-bottom: 1px var(--color-light-grey) solid;
}

.product-presentation-container .table-row .key {
  padding: 5px 0;
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px var(--color-light-grey) solid;
}

.product-presentation-container .table-row .value {
  padding: 5px;
  flex-grow: 1.7;
  flex-basis: 0;
}

.product-presentation-container .bottom-container {
  margin: 20px auto 10px;
  width: 80%;
  font-size: var(--text-size-phone-medium);
  color: var(--color-grey);
  text-align: center;
}

.product-presentation-container .bottom-container .contact-buttons-container {
  flex-direction: row;
}

.product-presentation-container .bottom-container .contact-buttons-container i {
  width: 35px;
}

@media all and (min-width: 768px) {
  .product-presentation-container .product-name {
    font-size: var(--text-size-tablet-large);
  }

  .product-presentation-container .content-container .text-container {
    font-size: var(--text-size-tablet-medium);
    line-height: 37px;
  }

  .product-presentation-container .product-price {
    font-size: var(--text-size-tablet-large);
  }

  .product-presentation-container .monthly-payment-container {
    line-height: 32px;
  }

  .product-presentation-container .extra-presentation {
    line-height: 37px;
    font-size: var(--text-size-tablet-medium);
  }

  .top-bttn {
    padding: 14px 20px;
    font-size: var(--text-size-tablet-medium);
  }

  .product-presentation-container .bottom-container {
    font-size: var(--text-size-tablet-medium);
  }

  .product-presentation-container .bottom-container .contact-buttons-container {
    width: 70%;
    margin: 0 auto;
  }

  .product-presentation-container
    .bottom-container
    .contact-buttons-container
    i {
    width: 50px;
  }
}

@media all and (min-width: 1025px) {
  .product-presentation-container .top-container {
    width: 65%;
    padding: 25px 0;
  }

  .top-bttn {
    font-size: var(--text-size-laptop-smaller);
    padding: 13px 20px;
    border-radius: 5px;
  }

  .top-bttn i {
    transition: all 0.2s ease;
  }

  .back-bttn:hover i {
    padding-right: 20px;
  }

  .order-bttn:hover i {
    padding-left: 20px;
  }

  .product-presentation-container .content-container .product-name,
  .product-presentation-container .content-container .product-price {
    font-size: var(--text-size-laptop-medium);
  }

  .product-presentation-container .content-container .text-container {
    font-size: var(--text-size-laptop-small);
    line-height: 35px;
    width: 550px;
    border-left: solid var(--color-light-grey) 2px;
    margin-left: 20px;
    padding-left: 40px;
  }

  .product-presentation-container .extra-presentation {
    width: 75%;
    font-size: var(--text-size-laptop-small);
    line-height: 35px;
  }

  .product-presentation-container .individual-product-container {
    width: 23%;
    border: none;
  }

  .product-presentation-container .bottom-container {
    margin-top: 70px;
    font-size: var(--text-size-laptop-small);
    width: 60%;
  }

  .product-presentation-container .casa-total .extra-presentation div {
    width: 40%;
  }
}
