nav {
  margin: 0 auto;
  height: 70px;
  position: sticky;
  top: 0;
  font-size: var(--text-size-phone-medium);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0622169);
  background-color: var(--color-fake-white);
  z-index: 5;
}

nav .toolbar-items {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

nav .menu-bttn {
  background-color: transparent;
  font-size: inherit;
  font-weight: bold;
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  color: var(--color-dark-blue);
}

nav .fa-bars {
  margin-left: 5px;
  text-align: center;
  /* border-radius: 5px;
  box-shadow: 0 10px 20px rgba(4,47,102,0.05),
              0 6px 6px rgba(4,47,102,0.05); */
}

@media all and (min-width: 768px) {
  nav {
    height: 100px;
    padding: 0 20px;
    font-size: var(--text-size-tablet-medium);
  }

  nav .open-bttn {
    height: 24px;
    width: 24px;
  }

  nav .open-bttn div {
    height: 3px;
  }
}

@media all and (min-width: 1025px) {
  nav {
    height: 70px;
    padding: 0;
  }
}

/* NAV SECTION ITEMS */

.nav-section-items-container {
  width: 100%;
  height: 100%;
  list-style: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-section-items-container li {
  padding: 5px 0;
  color: var(--color-dark-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text-size-phone-medium);
}

.nav-section-items-container li i {
  text-align: center;
  margin-left: 5px;
}

.nav-section-items-container li a {
  display: block;
  color: inherit;

  text-align: center;
  text-decoration: none;

  font-family: var(--font-primary);
}

.nav-section-items-container .link-highlight {
  color: var(--color-light-blue);
  border-bottom: solid 2px var(--color-light-blue);
}

@media all and (min-width: 768px) {
  .nav-section-items-container li {
    font-size: var(--text-size-tablet-medium);
  }
}

@media all and (min-width: 1025px) {
  .nav-section-items-container {
    margin: 0 auto;
    width: 90%;
  }

  .nav-section-items-container li {
    font-size: var(--text-size-laptop-small);
  }

  .nav-section-items-container li i {
    opacity: 0;
    transition: all 0.25s ease;
    transform: translate(0, -30%);
  }

  .nav-section-items-container li:hover i,
  .nav-section-items-container .link-highlight i {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* SLIDER */
.slider-white {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  transition: 0.15s ease-out;
  font-size: var(--text-size-phone-large);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 101;
}

.slider-white ul {
  font-weight: bold;
  flex-direction: column;
  height: 65%;
}

.slider-white .close-bttn {
  padding: 10px;
  color: var(--color-light-blue);
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.slider-white .contact-buttons-container {
  width: 50%;
}

@media all and (min-width: 768px) {
  .slider-white {
    width: 300px;
    border-radius: 14px 0 0 14px;
    font-size: var(--text-size-tablet-large);
  }
}
