.image-gallery-container {
  margin: 5px;
  position: relative;
  width: 300px;
}

.image-big {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 200px;
}

.image-big i {
  font-size: 1rem;
  padding: 5px;
  position: absolute;
  border-radius: 3px;
  color: var(--color-light-blue);
  background-color: rgba(255, 255, 255, 0.5);
}

.image-big .bttn-enlarge {
  top: 3px;
  right: 3px;
}

.image-big .bttn-increment {
  right: 3px;
  top: 50%;
}

.image-big .bttn-decrement {
  left: 3px;
  top: 50%;
}

.images-small {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}

.images-small img {
  margin: 5px;
  width: 62px;
  height: auto;
  border: solid var(--color-light-grey) 1px;
  cursor: pointer;
}

.images-small .selected-image {
  border: solid var(--color-light-blue) 2px;
}

@media all and (min-width: 768px) {
  .image-gallery-container {
    width: 500px;
  }

  .image-big {
    height: 333px;
  }

  .image-big i {
    font-size: 1.5rem;
  }

  .images-small img {
    margin: 7px;
    width: 83px;
  }
}

@media all and (min-width: 1025px) {
  .image-gallery-container {
    width: 500px;
  }

  .image-big {
    height: 333px;
    cursor: pointer;
  }

  .image-big i {
    font-size: 1.8rem;
  }

  .images-small img {
    margin: 8px;
    width: 81px;
  }
}
