.contact-section-container ul {
  list-style: none;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
}

.contact-section-container li {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--text-size-phone-medium);
}

.contact-section-container li .fa-lg {
  padding: 5px;
}

.contact-section-container li img {
  height: 26px;
  width: 26px;
  margin-right: 10px;
}

@media all and (min-width: 768px) {
  .contact-section-container li {
    padding: 15px;
    font-size: var(--text-size-tablet-medium);
  }

  .contact-section-container li .fa-lg {
    padding: 10px;
  }

  .contact-section-container img {
    height: 36px;
    width: 36px;
    margin-right: 15px;
  }
}

.fa-phone-square {
  color: #089404;
}

.fa-facebook-square {
  color: var(--color-light-blue);
}

.fa-envelope-square {
  color: #c80815;
}

.contact-buttons-container {
  font-size: var(--text-size-phone-medium);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-buttons-container i {
  margin: 10px;
  padding: 7px 0;
  text-align: center;
  color: white;
  width: 100px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media all and (min-width: 768px) {
  .contact-buttons-container {
    font-size: var(--text-size-tablet-medium);
  }

  .contact-buttons-container i {
    width: 150px;
    margin: 10px;
    padding: 10px 0;
  }
}

.contact-buttons-container .fa-phone {
  background-color: #089404;
}
.contact-buttons-container .fa-facebook-f {
  background-color: var(--color-light-blue);
}

.contact-buttons-container .fa-envelope {
  background-color: #c80815;
}

@media all and (min-width: 1025px) {
  .contact-section-container ul {
    flex-direction: row;
  }

  .contact-section-container li {
    padding: 25px 0;
    font-size: var(--text-size-laptop-small);
  }

  .contact-section-container li .fa-lg {
    padding: 15px;
  }

  .contact-section-container img {
    height: 42px;
    width: 42px;
    margin-right: 15px;
  }
}
