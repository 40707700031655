.home-big-container {
  font-family: var(--font-primary);
  color: var(--color-dark-blue);
}

.home-big-container .top-container {
  position: relative;
  height: 450px;
  background: url("/images/pure-water-background.jpg") no-repeat;
  background-position: 0 35%;
  background-size: 110% auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-big-container .logo-circle-container {
  margin-top: 40px;
}

.home-big-container .top-container h2 {
  font-size: var(--text-size-laptop-medium);
  width: 700px;
  font-weight: bold;
  text-align: center;
  line-height: 35px;
}

.pres-box-container p {
  padding: 0 4%;
}

.pres-box-container .head {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-light-blue);
  font-weight: bold;
}

.pres-box-container .head i {
  color: white;
  background-color: var(--color-light-blue);
  text-align: center;
  padding: 5px;
  margin-right: 15px;
  border-radius: 5px;
}

.home-big-container .presentation-section {
  position: relative;
  margin: 30px 0;

  display: flex;
  line-height: 30px;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
}

.home-big-container .pres-box-container {
  margin: 20px 10px;
  width: 30%;
  min-width: 420px;
}

.home-big-container .pres-box-container img {
  width: 100%;
  border-radius: 6px;
}

.home-big-container .pres-box-container .head {
  font-size: var(--text-size-laptop-medium);
}

.home-big-container .pres-box-container .body {
  font-size: var(--text-size-laptop-small);
}

.mid-section {
  font-size: var(--text-size-laptop-small);
}

#description-big {
  font-size: var(--text-size-laptop-medium);
  line-height: 30px;
  text-align: center;
  background: rgb(0, 124, 176);
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );
  color: white;
  padding: 20px;
}

#description-profi {
  background: rgb(0, 124, 176);
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );
  border-radius: 5px;
  color: white;
  padding: 20px;
  margin: 40px auto;
  width: 60%;
}

#description-profi a {
  color: inherit;
}

.home-big-container .contact-section-container {
  margin: 0 auto;
  width: 60%;
}
.home-big-container .contact-section-container ul {
  flex-direction: row;
}
