.contact-container .content-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contact-container .content-container .sub {
  min-width: 60%;
}
