.apa-curata-container .left-container {
  background-image: url('/images/clean-water.jpg');
  background-size: auto 100%;
  background-position: 20% 0;
}

.apa-curata-container .right-container {
  color: black;
  background-color: white;
}

.apa-curata-container .iqua-text-box {
  margin: 25px auto;
  padding: 20px;
  width: 75%;
  font-size: var(--text-size-phone-medium);
  line-height: 25px;
  background-color: var(--color-fake-white);
}

.apa-curata-container .outline-text {
  color: var(--color-light-blue);
  font-weight: bold;
}

@media all and (min-width: 768px) {
  .apa-curata-container .iqua-text-box {
    margin: 40px auto;
    font-size: var(--text-size-tablet-small);
    line-height: 30px;
  }
}

@media all and (min-width: 1025px) {
  .apa-curata-container .iqua-text-box {
    margin: 50px auto;
    font-size: var(--text-size-laptop-small);
    line-height: 35px;
  }

  .apa-curata-container .left-container h1 {
    color: white;
  }
}
