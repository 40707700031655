@font-face {
  font-family: "century-gothic";
  src: url(./fonts/century-gothic/gothic.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "segoe-ui-black";
  src: url(./fonts/segoe-ui-black/seguibl.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "england-signature";
  src: url(./fonts/england-signature/england-signature.otf);
  font-weight: lighter;
}

@font-face {
  font-family: "foglihten";
  src: url(./fonts/foglihten/foglihten.otf);
  font-weight: normal;
}

:root {
  --color-dark-blue: #042f66;
  --color-light-blue: #007cb0;
  --color-soft-blue: #f2f7ff;
  --color-grey: #1c2024;
  --color-light-grey: #c1c1c1;
  --color-fake-white: #fbfdff;

  --gradient-primary: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );

  --text-size-laptop-large: 1.5rem;
  --text-size-laptop-medium: 1.3rem;
  --text-size-laptop-small: 1.1rem;
  --text-size-laptop-smaller: 1.1rem;

  --text-size-tablet-large: 1.7rem;
  --text-size-tablet-medium: 1.4rem;
  --text-size-tablet-small: 1.2rem;

  --text-size-phone-large: 1.3rem;
  --text-size-phone-medium: 1rem;
  --text-size-phone-small: 0.7rem;

  --transition-fast: 0.2s ease;
  --transition-slow: 0.4s ease;

  --font-primary: century-gothic;
  --font-secondary: segoe-ui-black;
}

.iqua-text {
  font-family: century-gothic;
  color: var(--color-dark-blue);
}

.iqua-text-hoverable {
  transition: var(--transition-fast);
}
@media all and (min-width: 1025px) {
  .iqua-text-hoverable:hover {
    color: var(--color-grey);
  }
}

.iqua-text-special {
  font-family: segoe-ui-black;
  color: white;

  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-dark-blue);
}
@media all and (min-width: 768px) {
  .iqua-text-special {
    -webkit-text-stroke-width: 2px;
  }
}

.iqua-text-box {
  padding: 15px;
  /* box-shadow: -5px 5px 20px rgba(4, 47, 102, 0.2); */
  box-shadow: 0 10px 20px rgba(4, 47, 102, 0.05),
    0 6px 6px rgba(4, 47, 102, 0.1);
}

.about-container .content-container,
.apa-curata-container .content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-container .left-container,
.apa-curata-container .left-container {
  flex-grow: 1;
  flex-basis: 15%;
}

.about-container .left-container h1,
.apa-curata-container .left-container h1 {
  margin: 70px 0 30px;
  display: block;
  text-align: center;
  font-family: foglihten;
  font-size: var(--text-size-phone-large);
  font-weight: normal;
  color: black;
}

@media all and (min-width: 768px) {
  .about-container .left-container h1,
  .apa-curata-container .left-container h1 {
    font-size: var(--text-size-tablet-large);
  }
}

@media all and (min-width: 1025px) {
  .about-container .left-container h1,
  .apa-curata-container .left-container h1 {
    font-size: var(--text-size-laptop-large);
  }
}

.about-container .right-container,
.apa-curata-container .right-container {
  flex-grow: 3;
  flex-shrink: 0;
  flex-basis: 10%;
  height: 100%;
}
