.lang-selector-container {
  display: flex;
  align-items: center;
  font-size: var(--text-size-phone-medium);
  font-weight: bold;
  font-family: var(--font-primary);
  color: var(--color-dark-blue);
}

.lang-selector-container i {
  margin-right: 5px;
}

.lang-selector-container {
  width: 24px;
  height: 18px;
  position: relative;
}

.lang-selector-container button,
.lang-selector-container button img {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 3px;
  left: 0;
  top: 0;
}

@media all and (min-width: 768px) {
  .lang-selector-container {
    width: 32px;
    height: 24px;
  }

  .lang-selector-container button,
  .lang-selector-container button img {
    border-radius: 5px;
  }
}
/* 
@media all and (min-width: 1025px) {
  .lang-selector-container {
    width: 32px;
    height: 24px;
  }
} */
