.form-container {
  color: var(--color-grey);
  margin: 30px auto;
  width: 90%;
  font-family: var(--font-primary);
  font-size: var(--text-size-phone-medium);
  line-height: 30px;
}

.form-container a {
  font-weight: bold;
  color: var(--color-dark-blue);
}

.form-container .req-expl::before {
  content: "*";
  color: var(--color-light-blue);
  margin-right: 5px;
}

.input-wrapper {
  margin: 20px 0;
}

.input-wrapper p {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-wrapper p i {
  margin-right: 5px;
  color: var(--color-dark-blue);
}

.input-wrapper.required p::after {
  content: "*";
  color: var(--color-light-blue);
  margin-left: 5px;
}

.input-wrapper .user-input {
  border-radius: 2px;
  display: block;
  box-sizing: border-box;
  width: 90%;
  max-width: 500px;
  margin-top: 5px;
  padding: 12px;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background-color: var(--color-soft-blue);
  border: none;
  border-bottom: solid 1px var(--color-dark-blue);
  outline: none;
  transition: all 0.15s ease-in;
}

.input-wrapper .user-input:focus {
  border-bottom-color: var(--color-light-blue);
  background-color: white;
}

.input-wrapper .user-input[name="message"] {
  height: 120px;
  resize: none;
  overflow-y: auto;
}

.input-wrapper input[type="checkbox" i] {
  width: auto;
  display: inline-block;
  margin-left: 10px;
}

.bttn-submit-order {
  font-family: inherit;
  font-size: var(--text-size-phone-medium);
  background: var(--gradient-primary);
  color: white;
  padding: 8px 24px;
  border-radius: 3px;
}

.bttn-submit-order i {
  margin-left: 5px;
}

.form-log {
  margin-left: 20px;
}

.form-log i {
  margin-right: 10px;
}

.form-log .fa-spinner {
  color: var(--color-dark-blue);
}
.form-log .fa-times {
  color: #c80815;
}
.form-log .fa-check {
  color: #089404;
}

.rotate {
  animation: rotation 1.2s infinite cubic-bezier(0.24, 0.79, 0.8, 0.26);
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 768px) {
  .form-container {
    font-size: var(--text-size-tablet-small);
  }

  .bttn-submit-order {
    font-size: var(--text-size-tablet-medium);
    padding: 9px 27px;
  }
}

@media all and (min-width: 1025px) {
  .form-container {
    font-size: var(--text-size-laptop-small);
  }

  .input-wrapper {
    margin: 30px 0;
  }

  .input-wrapper .user-input {
    border-width: 2px;
  }

  .bttn-submit-order {
    font-size: var(--text-size-laptop-medium);
  }

  .bttn-submit-order span {
    display: inline-block;
    transform: translate(10px, 0);
    transition: all 0.2s ease;
  }

  .bttn-submit-order i {
    opacity: 0;
    transform: translate(-20px, 0);
    transition: all 0.2s ease;
  }

  .bttn-submit-order:hover i,
  .bttn-submit-order:hover span {
    transform: translate(0, 0);
    opacity: 1;
  }
}
