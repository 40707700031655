.image-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: var(--text-size-phone-large);
  z-index: 1000;
}

.image-viewer-container .close-bttn {
  position: absolute;
  text-align: center;
  top: 10px;
  right: 10px;
  color: var(--color-light-blue);
  padding: 10px;
  z-index: 1;
}

.image-viewer-container .main-image {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}

.image-viewer-container .ctrl-container {
  font-size: 1.5rem;
  width: 100%;
  position: absolute;
  top: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: var(--font-primary);
  justify-content: space-between;
  color: var(--color-light-blue);
}

.image-viewer-container .ctrl-container button {
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  margin: 0 10px;
}

@media all and (min-width: 768px) {
  .image-viewer-container .ctrl-container {
    font-size: 2rem;
  }

  .image-viewer-container {
    font-size: var(--text-size-tablet-large);
  }
}

.image-viewer-container .ctrl-container .image-index {
  font-weight: bold;
}

.image-viewer-container .ctrl-container button {
  color: inherit;
  background-color: white;
}

@media all and (min-width: 1025px) {
  .image-viewer-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .image-viewer-container .close-bttn {
    padding: 7px;
    font-size: 1.2rem;
    border-radius: 5px;
  }

  .image-viewer-container .left-images {
    position: relative;
    display: inline-flex;
    width: 20%;
    height: 100%;
    border-right: 2px var(--color-dark-blue) solid;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }

  .image-viewer-container .left-images img {
    width: 40%;
    border: 1px var(--color-light-grey) solid;
    margin: 3px;
    cursor: pointer;
  }

  .image-viewer-container .left-images .img-selected {
    border: 3px var(--color-light-blue) solid;
  }

  .image-viewer-container .right-image {
    position: relative;
    display: inline-block;
    flex-grow: 1;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
    cursor: zoom-in;
  }

  #zoom-toggle {
    background-size: 220% auto;
  }

  .image-viewer-container .ctrl-container {
    bottom: 0;
    font-size: var(--text-size-laptop-lage);
    justify-content: center;
    width: 100%;
  }

  .image-viewer-container .ctrl-container .image-index {
    padding: 10px;
  }
}
