.about-container .left-container {
  background: url("/images/water-glass.jpg") no-repeat;
  background-size: auto 100%;
  background-position: 55% 0;
  /* background: url('/images/pouring-water.jpg') no-repeat;
  background-size: cover;
  background-position: 50% 0; */
}

.about-container .right-container {
  padding: 40px 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    144deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(227, 241, 249, 1) 67%,
    rgba(179, 216, 238, 1) 87%,
    rgba(138, 195, 229, 1) 96%,
    rgba(117, 184, 224, 1) 99%,
    rgba(2, 124, 197, 0.8) 100%
  );
}

.about-container .right-container p {
  margin: 0 auto;
  width: 75%;
  padding: 25px 0;
  font-size: var(--text-size-phone-medium);
  line-height: 25px;
}

.about-container .signature {
  margin: 0 auto;
  width: 75%;
  font-family: england-signature;
  font-size: 3rem;
  font-weight: lighter;
  color: black;
}

@media all and (min-width: 768px) {
  .about-container .right-container p {
    font-size: var(--text-size-tablet-small);
    line-height: 30px;
  }

  .about-container .signature {
    font-size: 4rem;
  }
}

@media all and (min-width: 1025px) {
  .about-container .right-container p {
    font-size: var(--text-size-laptop-small);
    line-height: 35px;
  }

  .about-container .signature {
    font-size: 5rem;
  }
}
