.blog-post {
  margin: 30px auto;
  font-family: var(--font-primary);
  width: 88%;
  color: var(--color-grey);
}

.blog-post .top-container {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  font-size: var(--text-size-phone-medium);
  color: var(--color-dark-blue);
  align-items: center;
}

.blog-post .top-container .date {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog-post .top-container .date i {
  margin: 0 5px;
}

.blog-post .header {
  cursor: pointer;
  color: var(--color-light-blue);
  padding: 15px 0;
  font-size: var(--text-size-phone-large);
  display: inline-block;
}

.blog-post .header .bttn-show {
  color: inherit;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}

.blog-post .body {
  text-align: justify;
  font-size: var(--text-size-phone-medium);
}

@media all and (min-width: 768px) {
  .blog-post .top-container {
    font-size: var(--text-size-tablet-small);
  }

  .blog-post .header {
    font-size: var(--text-size-tablet-medium);
  }

  .blog-post .body {
    font-size: var(--text-size-tablet-small);
  }
}

@media all and (min-width: 1025px) {
  .blog-post .top-container {
    font-size: var(--text-size-laptop-small);
  }

  .blog-post .header {
    font-size: var(--text-size-laptop-medium);
  }

  .blog-post .body {
    font-size: var(--text-size-laptop-small);
  }
}
