.profi-container .content-container {
  font-family: var(--font-primary);
  color: var(--color-dark-blue);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.profi-container .profi-pres {
  font-size: var(--text-size-phone-large);
  text-align: center;
  width: 100%;
  margin: 20px 5px;
}

.profi-container .profi-pres img {
  width: 100%;
}

@media all and (min-width: 768px) {
  .profi-container .profi-pres {
    font-size: var(--text-size-tablet-medium);
  }
}

@media all and (min-width: 1025px) {
  .profi-container .profi-pres {
    font-size: var(--text-size-laptop-large);
    width: 45%;
  }
}
