.support-container .content {
  font-family: var(--font-primary);
  font-size: var(--text-size-phone-medium);
  color: var(--color-dark-blue);
  padding: 20px 5%;
}
.support-container .content h3 {
  margin: 20px 0;
}

.bttn-download {
  padding: 10px;
  margin: 15px 10px 15px 0;
  border-radius: 5px;
  display: inline-flex;
  text-decoration: none;
  color: white;
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );
  text-align: center;
}

.bttn-download i {
  text-align: center;
}

@media all and (min-width: 768px) {
  .support-container .content {
    font-size: var(--text-size-tablet-medium);
  }
}

@media all and (min-width: 1025px) {
  .support-container .content {
    font-size: var(--text-size-laptop-small);
  }
  .bttn-download {
    font-size: var(--text-size-laptop-smaller);
  }
}
