.mail-collector {
  position: relative;
  background-color: white;
  border-radius: 5px;
  margin: 30px auto 10px;
  width: 80%;
  box-sizing: border-box;
  font-family: var(--font-primary);
  color: var(--color-light-blue);
  font-size: var(--text-size-phone-medium);
}

.mail-collector .sub-text {
  padding-bottom: 10px;
  font-weight: bold;
}

.mail-collector .unsub-text {
  padding-bottom: 10px;
  color: var(--color-grey);
}

#mail-submit {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

#mail-input {
  font-family: inherit;
  font-size: inherit;
  display: inline-block;
  flex: 1;
  padding: 10px;
  margin-right: 5px;
  min-width: 280px;
  box-sizing: border-box;
  color: var(--color-grey);
  border-radius: 5px;
  border: 1px solid var(--color-light-grey);
}

#mail-input:focus {
  outline: 1px solid var(--color-grey);
}

#mail-submit button {
  padding: 11px 14px;
  margin: 10px 0;
  color: white;
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );
  border-radius: 5px;
  font-family: inherit;
  font-size: inherit;
  flex-grow: 0;
  flex-shrink: 0;
}

#mail-submit button i {
  margin-left: 5px;
}

.info-text {
  color: var(--color-grey);
}

@media all and (min-width: 768px) {
  .mail-collector {
    font-size: var(--text-size-tablet-medium);
    width: 700px;
  }
}

@media all and (min-width: 1025px) {
  .mail-collector {
    width: 60%;
    font-size: var(--text-size-laptop-small);
  }
}
