.rights-section-container {
  font-family: var(--font-primary);
  font-size: 15px;
  padding: 10px 0;
  background-color: var(--color-light-blue);
  text-align: center;
  color: white;
}

.rights-section-container.recolor {
  background-color: transparent;
  color: var(--color-dark-blue);
  border-top: 1px solid var(--color-light-grey);
}
.rights-section-container.recolor a {
  border-left: 1px solid var(--color-dark-blue);
}

.rights-section-container span {
  padding: 0 10px;
}

.rights-section-container a {
  padding: 0 10px;
  border-left: 1px solid white;
  display: inline-block;
  color: inherit;
}

@media all and (min-width: 1025px) {
  .rights-section-container {
    padding: 20px 0;
  }
}
