* {
  padding: 0;
  margin: 0;
}

div,
span,
main,
section {
  white-space: pre-wrap;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
}

ul {
  list-style: none;
}
