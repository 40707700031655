.home-small-container {
  color: var(--color-dark-blue);
  font-family: var(--font-primary);
}

.home-small-container .top-container {
  padding: 10px 0;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: url("/images/water-drop.jpg") no-repeat;
  background-size: 140% auto;
  background-position: 90% 50%;

  /* background: rgb(0, 124, 176);
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  ); */
}

.home-small-container .mid-container {
  font-size: var(--text-size-phone-medium);
  line-height: 30px;
}

.home-small-container .mid-container .text-1 {
  margin: 25px auto;
  font-weight: bold;
  width: 90%;
  text-align: center;
}

.home-small-container .presentation-section img {
  display: block;
  width: 80%;
  margin: auto;
  border-radius: 10px;
}

.home-small-container .pres-box-container {
  margin: 10px 0;
  position: relative;
}

.home-small-container .pres-box-container .head {
  font-size: var(--text-phone-large);
}

.home-small-container .pres-box-container img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.home-small-container .mid-container .text-3 {
  border-bottom: none;
}

.home-small-container .description-text {
  width: 85%;
  margin: 25px auto;
}

.home-small-container .description-big {
  text-align: center;
  /* background: rgb(0, 124, 176);
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  ); */
  background: linear-gradient(rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)),
    url("/images/presentation3.jpg") no-repeat;
  background-size: 100% auto;
  background-position: 0 50%;
  color: white;
  padding: 20px 10px;
  width: 100%;
  box-sizing: border-box;
}

.text-3.description-text {
  background: rgb(0, 124, 176);
  background: linear-gradient(
    145deg,
    rgba(0, 124, 176, 1) 0%,
    rgba(4, 47, 102, 1) 100%
  );
  color: white;
  padding: 10px;
  margin: 40px auto;
  width: 100%;
  box-sizing: border-box;
}

.text-3 a {
  color: inherit;
}

.home-small-container .bottom-container {
  height: 260px;
  position: relative;

  background: url("/images/pure-water-background.jpg") no-repeat;
  background-size: cover;
}

.home-small-container .bottom-container .rights-section-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media all and (min-width: 768px) {
  .home-small-container .mid-container .text-1 {
    margin: 30px auto;
  }

  .home-small-container .mid-container {
    font-size: var(--text-size-tablet-medium);
    line-height: 40px;
  }

  .home-small-container .mid-container .description-text {
    border-bottom-width: 2px;
    margin-top: 30px;
  }

  .home-small-container .bottom-container {
    height: 550px;
  }
}
