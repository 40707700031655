.product-box-container {
  text-decoration: none;
  font-family: var(--font-primary);
  position: relative;
  margin: 15px;
  width: 80%;
  padding: 15px 15px 20px;
}

.product-box-container .product-image {
  display: block;
  height: auto;
  width: 100%;
  margin: auto;
}

.product-box-container .product-text-container {
  position: relative;
  padding: 10px;
}

.product-box-container .name {
  display: inline-block;
  padding: 5px;
  padding-left: 0;

  color: var(--color-light-blue);
  font-size: var(--text-size-phone-large);
  border-bottom: solid 2px var(--color-light-blue);
}

.product-box-container .description {
  color: var(--color-grey);
  padding: 10px 0;
  font-size: var(--text-size-phone-medium);
  line-height: 25px;
}

.product-box-container .price {
  font-weight: bold;
  text-align: left;
  color: var(--color-dark-blue);
  font-size: var(--text-size-phone-large);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.from {
  font-size: 0.5em;
}

.tva {
  font-size: 0.5em;
}

.product-box-container .find-out-more-bttn {
  display: inline-block;
  color: var(--color-dark-blue);
  font-weight: bold;
  border-radius: 3px;
}

.product-box-container .find-out-more-bttn i {
  padding-left: 5px;
  padding-right: 0;
}

.product-box-container .interest-free-img {
  height: 70px;
  width: 140px;
  margin-left: 20px;
  margin-bottom: -35px;
}

.product-box-container .made-in-italy {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 16px;
}

.product-box-container .status {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: var(--color-grey);
  font-size: var(--text-size-phone-small);
}

.product-box-container .interest-free-img {
  width: 110px;
  height: 50px;
}

@media all and (min-width: 768px) {
  .product-box-container {
    margin: 25px;
  }

  .product-box-container .product-text-container {
    padding: 15px;
  }

  .product-box-container .name {
    padding: 10px 0;
    color: var(--color-light-blue);
    font-size: var(--text-size-tablet-large);

    border-bottom: solid 2px var(--color-light-blue);
  }

  .product-box-container .description {
    padding: 15px 0;
    line-height: 35px;
    font-size: var(--text-size-tablet-medium);
  }

  .product-box-container .price {
    font-size: var(--text-size-tablet-large);
    margin-bottom: 15px;
  }

  .product-box-container .find-out-more-bttn {
    left: 15px;
    font-size: var(--text-size-tablet-small);
    border-radius: 5px;
  }

  .product-box-container .made-in-italy {
    top: 20px;
    left: 20px;
    width: 90px;
    height: 18px;
  }

  .product-box-container .status {
    font-size: var(--text-size-tablet-small);
  }
}

@media all and (min-width: 1025px) {
  .product-box-container {
    margin: 30px 15px;
    width: 800px;
    height: 420px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    transition: var(--transition-slow);
  }

  .product-box-container .product-image {
    width: 50%;
  }

  .product-box-container .product-text-container {
    padding: 0;
    width: 45%;
    height: 350px;
  }

  .product-box-container .name {
    padding: 0 0 15px;
    font-size: var(--text-size-laptop-medium);

    transition: var(--transition-fast);
  }

  .product-box-container .description {
    line-height: 25px;
    font-size: var(--text-size-laptop-smaller);
  }

  .product-box-container .price {
    font-size: var(--text-size-laptop-medium);
  }

  .product-box-container .status {
    font-size: var(--text-size-laptop-smaller);
    right: auto;
    left: 20px;
    bottom: 20px;
  }

  .product-box-container .find-out-more-bttn {
    font-size: var(--text-size-laptop-smaller);
    left: 0;
  }

  .product-box-container .find-out-more-bttn i {
    transition: all 0.2s ease-out;
  }

  .product-box-container .find-out-more-bttn:hover i {
    padding-left: 20px;
  }

  .product-box-container:hover {
    box-shadow: none;
  }
}
