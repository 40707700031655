.products-page-content-container {
  margin: 20px 0;
  padding: 30px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cathegory-container .cathegory-name {
  width: 100%;
  font-size: var(--text-size-phone-large);
  font-family: var(--font-primary);
  color: var(--color-dark-blue);
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0;
}

@media all and (min-width: 768px) {
  .cathegory-container .cathegory-name {
    font-size: var(--text-size-tablet-large);
  }
}
@media all and (min-width: 1025px) {
  .cathegory-container {
    flex-direction: row;
  }

  .cathegory-container .cathegory-name {
    font-size: var(--text-size-laptop-large);
  }
}
