.logo-circle-container {
  width: 140px;
  height: 140px;
  display: inline-flex;

  background-color: white;

  border-radius: 200px;
}

.logo-circle-container img {
  margin: auto;
  width: 85%;
  height: 85%;
}

@media all and (min-width: 768px) {
  .logo-circle-container {
    height: 220px;
    width: 220px;
  }
}

@media all and (min-width: 1025px) {
  .logo-circle-container {
    height: 200px;
    width: 200px;
  }
}
